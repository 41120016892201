import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { DevisFactureCard } from '@travauxlib/shared/src/features/DevisDisplay/components/DevisFactureCard';
import { FactureSoldeTotals } from '@travauxlib/shared/src/utils/facture/getFactureSoldeTotals';

import { Facture } from 'types';

import { MontantsTVA } from './MontantsTVA';

export type Props = {
  factureSoldeTotals: FactureSoldeTotals;
  facture: Facture;
};

const ModalitePaiementComponent = (days: number): React.ReactElement => (
  <small className="text-gray-600" data-testid="modalites-paiement">
    Règlement par virement -{' '}
    {days === 0 ? 'dès réception' : `Sous ${days} jour${days > 1 ? 's' : ''} après réception`}
    <br />
    Pas d&apos;escompte en cas de paiement anticipé.
    <br />
    Tout retard de paiement sera passible d&apos;une pénalité de 2,5 fois le taux légal en vigueur.
    <br />
    Ainsi que d&apos;une indemnité forfaitaire pour frais de recouvrement de 40 €
    <br />
    (Loi n°2012-387 du 22 mars 2012 du Code de Commerce)
    <br />
  </small>
);

export const FactureSoldePrestationsSummary: React.FC<Props> = ({
  factureSoldeTotals,
  facture,
}) => {
  const { totalHT, montantsTVA, totalTTC } = factureSoldeTotals;

  return (
    <DevisFactureCard className="!mb-[5.5rem]">
      <div className="flex mb-xs justify-between items-center">
        <div className="text-b1 font-bold">
          Total
          <span className="font-normal text-b2 text-neutral-600"> HT</span>
        </div>
        <div data-testid="totalHT" className="text-b1 font-bold">
          <EURCurrency amount={totalHT} />
        </div>
      </div>
      <MontantsTVA montantsTVA={montantsTVA} lots={facture.lots} />
      <div className="flex mb-xs justify-between items-center">
        <div className="text-h5 font-bold">
          Total
          <span className="font-normal text-neutral-600"> TTC</span>
        </div>
        <div data-testid="totalTTC" className="text-h5 font-bold">
          <EURCurrency amount={totalTTC} />
        </div>
      </div>
      {facture.isAvoir ? null : ModalitePaiementComponent(facture.delaiPaiement)}
    </DevisFactureCard>
  );
};
