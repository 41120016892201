import React from 'react';

import { useParams } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { NotFound } from 'features/NotFoundPage';
import { BonCommandeClient } from 'types';

import { useBonCommande } from '../api/useBonCommande';

export const WithBonCommande = <P extends object>(
  Component: React.ComponentType<P & { bonCommande: BonCommandeClient }>,
): React.ComponentType<P> => {
  const Wrapper = (props: P): React.ReactElement => {
    const { token } = useParams();
    const { isLoading, bonCommande } = useBonCommande(token!);
    if (isLoading) {
      return <Loader />;
    }
    if (!bonCommande) {
      return <NotFound />;
    }
    return <Component {...props} bonCommande={bonCommande} />;
  };
  return Wrapper;
};
