import React from 'react';

import { OrExclusive } from '@travauxlib/shared/src/types/utils';

import { Header } from './Header';

type HrefOrTo = OrExclusive<{ logoHref: string }, { logoTo: string }>;

type Props = {
  logoUrl?: string;
  rightLink?: React.ReactElement | null;
  children: React.ReactNode;
} & HrefOrTo;

export const Layout: React.FC<Props> = ({ rightLink, logoUrl, children, logoTo, logoHref }) => (
  <div className="bg-neutral-100 h-screen flex flex-col relative overflow-auto">
    <Header logoTo={logoTo} logoHref={logoHref} logoUrl={logoUrl} rightLink={rightLink} />
    {children}
  </div>
);
