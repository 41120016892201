import React from 'react';

import classNames from 'classnames';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import { Input } from '@travauxlib/shared/src/components/DesignSystem/components/Input';
import { DragWidget } from '@travauxlib/shared/src/components/DragWidget';
import { DevisLocation } from '@travauxlib/shared/src/types';

type Props = {
  isDragging: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  location: DevisLocation;
  onEditLocation: (location: DevisLocation) => void;
  onDeleteLocation: (location: DevisLocation) => void;
  lastRow?: boolean;
};

export function LocationRow({
  isDragging,
  dragHandleProps,
  onEditLocation,
  onDeleteLocation,
  location,
  lastRow,
}: Props): React.ReactElement {
  return (
    <div className={classNames('!flex !items-center', { '!mb-md': !lastRow })}>
      <DragWidget
        isDragging={isDragging}
        dragHandleProps={dragHandleProps}
        className="!mr-md !mb-sm mt-sm"
      />
      <div className="!relative !flex-grow !rounded">
        <Input
          data-testid={location.label}
          id={location.label}
          label="Localisation"
          maxLength={25}
          onChange={label => onEditLocation({ ...location, label })}
          value={location.label}
          className={classNames({ '!shadow-lg': isDragging })}
        />
        <CrossSymbol
          className="w-xmd h-xmd cursor-pointer absolute top-[0.9rem] right-[0.7rem] text-danger"
          onClick={() => onDeleteLocation(location)}
          data-testid={`${location.label}-delete-button`}
        />
      </div>
    </div>
  );
}
