import React from 'react';

import { Form } from 'react-final-form';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { required } from '@travauxlib/shared/src/utils/form';

import { QuestionProps } from 'features/Estimateur/types';

import { Title } from '../QuestionTitle';

export const Elec = ({ submitAnswer, initialValues }: QuestionProps): React.ReactElement => (
  <Form initialValues={initialValues} onSubmit={submitAnswer}>
    {({ handleSubmit, valid, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="mb-xl">
          <Title title="Électricité" />
          <div className="mb-lg">
            <FormLabel label="Faut-il refaire toute l'installation électrique ?" />
            <ButtonGroupField
              validate={required}
              name="needsTotalRenovation"
              options={[
                { label: 'Oui', value: 'yes' },
                { label: 'Non', value: 'no' },
                { label: 'Je ne sais pas', value: 'unknown' },
              ]}
            />
          </div>
          <div className="mb-lg">
            <FormLabel label="Est-ce qu'il y a la terre ?" />
            <ButtonGroupField
              validate={required}
              name="thereIsTheGround"
              options={[
                { label: 'Oui', value: 'yes' },
                { label: 'Non', value: 'no' },
                { label: 'Je ne sais pas', value: 'unknown' },
              ]}
            />
          </div>
          <div className="mb-lg">
            <FormLabel label="L'installation électrique est-elle vétuste (+ de 20 ans) ?" />
            <ButtonGroupField
              validate={required}
              name="isInstallationOld"
              options={[
                { label: 'Oui', value: 'yes' },
                { label: 'Non', value: 'no' },
              ]}
            />
          </div>
          <div className="mb-lg">
            <FormLabel label="Faut-il déplacer l'emplacement du tableau électrique et/ou du disjoncteur général ?" />
            <ButtonGroupField
              validate={required}
              name="switchboardNeedsToBeMoved"
              options={[
                { label: 'Oui', value: 'yes' },
                { label: 'Non', value: 'no' },
              ]}
            />
          </div>
        </div>
        <div className="text-center">
          <Button type="submit" disabled={!valid || submitting} loading={submitting}>
            Valider
          </Button>
        </div>
      </form>
    )}
  </Form>
);
