import AddDocPicto from '@travauxlib/shared/src/components/DesignSystem/assets/AddDocPicto.svg?react';
import AdvisorsPicto from '@travauxlib/shared/src/components/DesignSystem/assets/AdvisorsPicto.svg?react';
import Assistance from '@travauxlib/shared/src/components/DesignSystem/assets/Assistance.svg?react';
import Brand from '@travauxlib/shared/src/components/DesignSystem/assets/Brand.svg?react';
import CalculatePicto from '@travauxlib/shared/src/components/DesignSystem/assets/CalculatePicto.svg?react';
import CalendarPicto from '@travauxlib/shared/src/components/DesignSystem/assets/CalendarPicto.svg?react';
import ChantierPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ChantierPicto.svg?react';
import Clock from '@travauxlib/shared/src/components/DesignSystem/assets/Clock.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/Construction.svg?react';
import HousePicto from '@travauxlib/shared/src/components/DesignSystem/assets/HousePicto.svg?react';
import InfoCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleOutline.svg?react';
import ListPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ListPicto.svg?react';
import OpenTab from '@travauxlib/shared/src/components/DesignSystem/assets/OpenTab.svg?react';
import ProjectPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectPicto.svg?react';
import QuotePicto from '@travauxlib/shared/src/components/DesignSystem/assets/QuotePicto.svg?react';
import RealEstateOutline from '@travauxlib/shared/src/components/DesignSystem/assets/RealEstateOutline.svg?react';
import SearchCalendarPicto from '@travauxlib/shared/src/components/DesignSystem/assets/SearchCalendarPicto.svg?react';
import Tipsheet from '@travauxlib/shared/src/components/DesignSystem/assets/Tipsheet.svg?react';
import WorkmenPicto from '@travauxlib/shared/src/components/DesignSystem/assets/WorkmenPicto.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links/Link';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { Tab } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs/types';
import { GuidesClientEventProperties } from '@travauxlib/shared/src/utils/tracking';
import { baseImgixUrl, buildImgixURL } from '@travauxlib/shared/src/utils/urls';

import { GuidesClientTracking } from 'utils/tracking/GuidesTracking';

import {
  accompagnementGuideUrl,
  architecteGuideUrl,
  architecteInterieurWebsiteUrl,
  architecteWebsiteUrl,
  guidesBaseUrl,
  garantiesWebsiteUrl,
  guidesImgixUrl,
  offresAccompagnementWebsiteUrl,
  offresWebsiteUrl,
  protectionWebsiteUrl,
  receptionGuideUrl,
  receptionWebsiteUrl,
} from './urls';

import { ArticleListItems } from '../components/Articles/ArticleListItems';
import { ArticleConfig, HemeaOfferTag } from '../types';

const trackRedirectionLinkEvent = (pageContaingLink: string): void =>
  GuidesClientTracking.onGuidesClientRedirectionLinkClicked({
    [GuidesClientEventProperties.PageContainingLink]: pageContaingLink,
  });

const numberIconClassnames =
  'text-h1 font-medium border-2 border-primary rounded-full w-full h-full flex items-center justify-center';

export const hemeaConcept: ArticleConfig[] = [
  {
    title: <>Qu’est-ce que hemea&nbsp;?</>,
    articleHeight: 188,
    image: {
      position: 'right',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/hemea-concept.jpg`),
        alt: 'photo employés hemea',
      },
    },
    content: (
      <>
        L’accompagnement personnalisé hemea facilite tous vos projets d’architecture et de
        rénovation en France. Le respect du planning, du budget et de vos envies sont les
        maîtres-mots de l’accompagnement sur-mesure réalisé par nos experts.
      </>
    ),
    link: {
      position: 'right',
      element: (
        <Link
          href={offresWebsiteUrl}
          target="_blank"
          rel="noopener"
          icon={<OpenTab />}
          className="text-b1"
          onClick={() => trackRedirectionLinkEvent('les offres')}
        >
          En savoir plus
        </Link>
      ),
    },
  },
  {
    title: 'Etude de votre projet',
    content: (
      <>
        <div className="text-b1 font-bold">Discutons ensemble de vos besoins et envies.</div>
        <ArticleListItems>
          <li>
            Après avoir répondu à notre questionnaire, vous échangez avec votre Chef de Projet lors
            d’une visioconférence de 30 min afin de discuter de votre projet.
          </li>
          <li>En moins de 48h, vous recevez une première estimation du budget et du planning.</li>
        </ArticleListItems>
      </>
    ),
    icon: {
      position: 'left',
      element: <div className={numberIconClassnames}>1</div>,
      size: '2xl',
    },
  },
  {
    title: 'Conception des plans',
    content: (
      <>
        <div className="text-b1 font-bold">
          Passons à une étape passionnante : la conception de votre intérieur.
        </div>
        <ArticleListItems>
          <li>
            Votre architecte hemea vous guide pour optimiser votre intérieur et façonne deux pistes
            d'aménagement en plans 2D. Il décline votre préférée en 3D et vous choisissez ensemble
            les matériaux.
          </li>
          <li>
            Il crée ensuite les plans techniques et les descriptifs nécessaires à la réalisation des
            travaux.
          </li>
        </ArticleListItems>
      </>
    ),
    icon: {
      position: 'left',
      element: <div className={numberIconClassnames}>2</div>,
      size: '2xl',
    },
    link: {
      position: 'right',
      element: (
        <Link to={architecteGuideUrl} className="text-b1">
          Voir les différents architectes
        </Link>
      ),
    },
  },
  {
    title: 'Exécution des travaux',
    content: (
      <>
        <div className="text-b1 font-bold">
          Place aux travaux : vous rencontrez deux entrepreneurs labellisés hemea, adaptés à votre
          projet.
        </div>
        <ArticleListItems>
          <li>
            Nous vous présentons les différents devis et vous accompagnons dans la négociation des
            tarifs.
          </li>
          <li>
            Vous choisissez l’entrepreneur et le type de suivi de chantier (l'offre Expertise ou
            Support).
          </li>
        </ArticleListItems>
      </>
    ),
    icon: {
      position: 'left',
      element: <div className={numberIconClassnames}>3</div>,
      size: '2xl',
    },
    link: {
      position: 'right',
      element: (
        <Link to={accompagnementGuideUrl} className="text-b1">
          Voir les accompagnements
        </Link>
      ),
    },
  },
  {
    title: 'Livraison du chantier',
    content: (
      <>
        <div className="text-b1 font-bold">
          Il est l’heure de découvrir le résultat : nous organisons un rendez-vous pour valider la
          conformité du projet.
        </div>
        <ArticleListItems>
          <li>
            Le chantier est terminé lorsque vous êtes 100 % satisfait de vos travaux. C’est vous qui
            donnez le feu vert.
          </li>
          <li>
            Une fois le chantier fini, livré à l’heure et à votre image, posez vos cartons et
            profitez&nbsp;!
          </li>
        </ArticleListItems>
      </>
    ),
    icon: {
      position: 'left',
      element: <div className={numberIconClassnames}>4</div>,
      size: '2xl',
    },
    link: {
      position: 'right',
      element: (
        <Link to={receptionGuideUrl} className="text-b1">
          En savoir plus
        </Link>
      ),
    },
  },
];

export const garanties: { introduction: React.ReactElement; articles: ArticleConfig[] } = {
  introduction: (
    <>
      Afin de couvrir les risques liés au chantier, la loi impose à l'entrepreneur en charge de vos
      travaux de souscrire des assurances BTP offrant certaines garanties au responsable du
      chantier.
    </>
  ),
  articles: [
    {
      title: 'Garantie décennale dans le BTP',
      content: (
        <>
          <p>
            Cette garantie est destinée à couvrir pendant 10 ans tous les dommages, même résultant
            d'un vice du sol, qui compromettent la solidité de l'ouvrage ou qui l'affectent, le
            rendant impropre à sa destination.
          </p>
          L'assurance décennale des constructeurs couvre les dommages touchant&nbsp;:
          <ArticleListItems>
            <li>les ouvrages de fondation et d'ossature</li>
            <li>les ouvrages de viabilité (réseaux, assainissement)</li>
            <li>la voirie (chemin d'accès)</li>
            <li>les ouvrages avec fondations (véranda, terrasse, piscine enterrée, etc.)</li>
            <li>
              les éléments d'équipement indissociables du bâtiment (canalisation, plafond, plancher,
              chauffage central, huisseries, etc.)
            </li>
          </ArticleListItems>
        </>
      ),
    },
    {
      title: 'Garantie de responsabilité civile professionnelle',
      content:
        'L’assurance responsabilité civile professionnelle a pour objet de protéger les entrepreneurs contre les conséquences de leurs erreurs, fautes ou omissions comis dans le cadre de leur activité.',
    },
    {
      title: 'Garantie biennale de bon fonctionnement',
      content:
        "Cette garantie, d'une durée de 2 ans, oblige le constructeur à assurer le bon fonctionnement des équipements non couverts par la garantie décennale (cloison mobile, ferrures de fenêtre, canalisations extérieures non intégrées dans le sol...).",
    },
    {
      title: 'Garantie de parfait achèvement',
      content:
        "Si vous émettez des réserves dans l'acte de réception des travaux (procès-verbal de parfait-achèvement), l'entrepreneur doit, dans un délai d'un an à compter de l’établissement du procès verbal, réparer tous les désordres apparents signalés par le maître de l'ouvrage.",
    },
    {
      isTipSheet: true,
      title: 'Dans le même thème',
      content:
        'Continuer à en savoir plus sur les différentes garanties indispensables à votre projet de rénovation.',
      icon: {
        position: 'left',
        element: <Tipsheet />,
      },
      link: {
        element: (
          <Link
            href={garantiesWebsiteUrl}
            target="_blank"
            rel="noopener"
            icon={<OpenTab />}
            className="text-b2"
            onClick={() => trackRedirectionLinkEvent('les garanties')}
          >
            Lire la suite
          </Link>
        ),
      },
    },
  ],
};

export const interlocuteurs: ArticleConfig[] = [
  {
    isWrapped: true,
    title: 'Chef de projet',
    image: {
      position: 'top',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/chef-projet.jpg`),
        alt: 'photo chef de projet hemea',
      },
    },
    content: (
      <>
        Le Chef de projet est le premier interlocuteur avec qui vous allez échanger. Il écoute votre
        besoin et le transforme en projet pour ensuite vous mettre en relation avec deux
        entrepreneurs du bâtiment. Il vous aide à challenger vos devis, à les relire et vous
        accompagne jusqu’à la signature.
      </>
    ),
  },
  {
    isWrapped: true,
    title: 'Architecte',
    image: {
      position: 'top',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/architecte.jpg`),
        alt: 'photo architectes hemea',
      },
    },
    content: (
      <>
        Architecte, architecte d'intérieur, décorateur d'intérieur... Autant de professionnels de
        l'architecture capables de vous accompagner dans la rénovation et l'aménagement de votre
        intérieur. Chacun possède des compétences et un périmètre d'intervention particuliers.
      </>
    ),
    link: {
      position: 'right',
      element: (
        <Link to={architecteGuideUrl} className="text-b1">
          En savoir plus
        </Link>
      ),
    },
  },
  {
    isWrapped: true,
    title: 'Chargé d’accompagnement travaux',
    image: {
      position: 'top',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/customer-care.jpg`),
        alt: 'photo responsables chargé d’accompagnement travaux',
      },
    },
    content: (
      <>
        Le chargé d’accompagnement travaux intervient à distance, en cas de questions ou de
        problèmes et en complément de vos propres visites sur votre chantier. Cet accompagnement est
        adapté à ceux qui ont le temps et l’expertise nécessaire au suivi de leur chantier. Ce suivi
        fait partie de l’offre Support.
      </>
    ),
    link: {
      position: 'right',
      element: (
        <Link to={accompagnementGuideUrl} className="text-b1">
          En savoir plus
        </Link>
      ),
    },
  },
  {
    isWrapped: true,
    title: 'Travaux Planner',
    image: {
      position: 'top',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/travaux-planner.jpg`),
        alt: 'photo travaux planner hemea',
      },
    },
    content: (
      <>
        Le Travaux Planner intervient une fois par semaine sur votre chantier pour effectuer un
        suivi. Chaque visite est ponctuée d’un rapport et de photos de votre intérieur qu’il vous
        communique par email. Il est présent à chaque étape de votre projet. Ce suivi fait partie de
        l’offre Expertise.
      </>
    ),
    link: {
      position: 'right',
      element: (
        <Link to={accompagnementGuideUrl} className="text-b1">
          En savoir plus
        </Link>
      ),
    },
  },
  {
    isWrapped: true,
    title: 'Entreprise labellisée',
    image: {
      position: 'top',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/entreprise-labellisee.jpg`),
        alt: 'photo ouvrier bâtiment',
      },
    },
    content: (
      <>
        Les entrepreneurs qui réalisent vos travaux sont rigoureusement labellisés par hemea en
        fonction de&nbsp;:
        <ArticleListItems>
          <li>la conformité à la loi et l’ancienneté (plus de 2 ans)</li>
          <li>la rencontre avec l’entreprise</li>
          <li>la notation et l’évaluation de nos clients.</li>
        </ArticleListItems>
      </>
    ),
  },
];

export const accompagnement: ArticleConfig[] = [
  {
    title: 'L’offre Support',
    image: {
      position: 'left',
      imageProps: {
        url: buildImgixURL(`${baseImgixUrl}/static/espace-client/estimer-un-projet.jpeg`),
        alt: 'photo chambre',
      },
    },
    content: (
      <>
        Cet accompagnement est adapté à ceux qui ont le temps et l’expertise nécessaire au suivi de
        leur chantier.
        <br />
        Votre chargé d'accompagnement travaux intervient à distance, en cas de questions ou de
        problèmes et en complément de vos propres visites sur votre chantier.
        <br />
        <br />
        Nous ne recommandons pas l’offre Support si vous n’avez ni les connaissances, ni le temps
        pour suivre votre chantier.
      </>
    ),
  },
  {
    title: 'L’offre Expertise',
    image: {
      position: 'right',
      imageProps: {
        url: buildImgixURL(`${baseImgixUrl}/static/espace-client/parrainage.jpg`),
        alt: 'photo cuisine',
      },
    },
    content: (
      <>
        Cet accompagnement est parfait pour ceux qui souhaitent tout déléguer et qui n’ont ni le
        temps et ni les connaissances pour suivre les travaux.
        <br />
        Votre Travaux Planner intervient une fois par semaine sur votre chantier pour effectuer un
        suivi et réaliser un rapport avec des photos de votre intérieur.
        <br />
        <br />
        Le Travaux Planner est là à chaque étape de votre projet : suivi des commandes, gestion du
        planning du chantier, vérification des finitions en détail. Il intervient également à vos
        côtés lors de la visite de fin de chantier.
      </>
    ),
  },
  {
    isTipSheet: true,
    title: 'Vous ne savez pas quel accompagnement choisir ?',
    content: "Comparez les deux offres d'accompagnement pour chacune des étapes de votre projet",
    icon: {
      position: 'left',
      element: <Tipsheet />,
    },
    link: {
      element: (
        <Link
          href={offresAccompagnementWebsiteUrl}
          target="_blank"
          rel="noopener"
          icon={<OpenTab />}
          className="text-b2"
          onClick={() => trackRedirectionLinkEvent('offres accompagnement')}
        >
          Lire la suite
        </Link>
      ),
    },
  },
];

const architectesTabs: Tab[] = [
  {
    label: 'Architecte d’intérieur',
    to: `${guidesBaseUrl}/${HemeaOfferTag.ArchitectesInterieur}`,
  },
  {
    label: 'Architecte',
    to: `${guidesBaseUrl}/${HemeaOfferTag.Architectes}`,
  },
];

export const architecte: { introduction: React.ReactElement; articles: ArticleConfig[] } = {
  introduction: <Tabs items={architectesTabs} buttonClassNames="bg-neutral-600" />,
  articles: [
    {
      noBackground: true,
      title: 'Architecte DPLG/DE/HMONP',
      content: (
        <>
          Pas toujours évident de s'y retrouver dans les différentes appellations utilisées pour
          qualifier les architectes.
        </>
      ),
    },
    {
      title: 'Architecte DPLG',
      articleHeight: 172,
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/architecte-dplg.jpg`),
          alt: 'image architecte',
        },
      },
      content: (
        <>
          Ce titre permet d’exercer la maîtrise d’œuvre en nom propre, c’est-à-dire de mettre en
          œuvre un projet dans les conditions fixées par un cahier des charges. L’architecte peut
          alors endosser toutes les responsabilités quant à la réalisation d’un projet
          d’architecture de plus de 150 m² (nécessitant l'obtention d'un permis de construire).
        </>
      ),
    },
    {
      title: "Architecte DE : un professionnel non habilité à la maîtrise d'oeuvre",
      articleHeight: 172,
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/architecte-de.jpg`),
          alt: 'image architecte',
        },
      },
      content: (
        <>
          Celui-ci permet d'exercer le métier d'architecte au sein d'une agence ou en tant que
          fonctionnaire, mais ne permet pas de réaliser de la maîtrise d'oeuvre. On entend par
          maîtrise d'oeuvre la responsabilité d'un projet de construction de plus de 150 m² en son
          propre nom.{' '}
        </>
      ),
    },
    {
      title: 'Architecte DE-HMONP : équivalent actuel du titre DPLG',
      articleHeight: 172,
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/architecte-de-hmonp.jpg`),
          alt: 'image architecte',
        },
      },
      content: (
        <>
          Pour exercer la maîtrise d’œuvre, les architectes DE doivent obtenir une licence
          d'exercice appelée Habilitation à l'exercice de la Maîtrise d'Œuvre en son Nom Propre
          (HMONP). Cette habilitation leur permet d'exercer en libéral ou au sein d'un cabinet
          d'architectes, au même titre qu'un architecte DPLG.
        </>
      ),
    },
    {
      isTipSheet: true,
      title: 'Dans le même thème',
      content: 'Continuer à en savoir plus sur les différents métiers d’architecture.',
      icon: {
        position: 'left',
        element: <Tipsheet />,
      },
      link: {
        element: (
          <Link
            href={architecteWebsiteUrl}
            target="_blank"
            rel="noopener"
            icon={<OpenTab />}
            className="text-b2"
            onClick={() => trackRedirectionLinkEvent('les architectes')}
          >
            Lire la suite
          </Link>
        ),
      },
    },
  ],
};

export const architecteInterieurDecorateur: ArticleConfig[] = [
  {
    title: 'Architecte d’intérieur',
    image: {
      position: 'left',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/architecte-interieur.jpg`),
        alt: 'photo architecte hemea',
      },
    },
    content: (
      <>
        L'architecte d'intérieur redéfinit l'agencement d’un espace de façon à le rendre plus
        esthétique, pratique et sûr.
        <br />
        Il intervenient sur le choix des matériaux, des couleurs, des équipements et des meubles. Il
        peut d'ailleurs être amené à créer des meubles sur-mesure et modifier des cloisons internes.
        <br />
        Il ne pourra intervenir sur la suppression ou la modification d’un mur porteur.
        <br />
        L’architecte d’intérieur propose des esquisses et plans 3D afin d'illustrer sa vision du
        projet.
      </>
    ),
    link: {
      position: 'right',
      element: (
        <Link
          href={architecteInterieurWebsiteUrl}
          target="_blank"
          rel="noopener"
          icon={<OpenTab />}
          className="text-b1"
          onClick={() => trackRedirectionLinkEvent("les architectes d'intérieur")}
        >
          En savoir plus
        </Link>
      ),
    },
  },
  {
    title: 'Décorateur d’intérieur',
    image: {
      position: 'left',
      imageProps: {
        url: buildImgixURL(`${guidesImgixUrl}/decorateur-interieur.jpg`),
        alt: 'photo décorateurs hemea',
      },
    },
    content: (
      <>
        Le décorateur d’intérieur repense l'ambiance d'un volume donné, en créant une harmonie grâce
        à des rénovations légères (nouveaux meubles et revêtements ou encore un changement de
        couleur). Le décorateur d’intérieur propose des planches de tendances pour illustrer sa
        vision du projet.
        <br />
        Il ne pourra intervenir ni sur la structure, ni sur l'agencement intérieur du bâtiment.
      </>
    ),
  },
];

export const fournitures: { introduction: React.ReactElement; articles: ArticleConfig[] } = {
  introduction: <> Pourquoi acheter vos fournitures avec hemea&nbsp;? </>,
  articles: [
    {
      title: 'Une facture finale moins élevée',
      content: (
        <>
          Si vous choisissez d'acheter vos équipements ou matériaux vous-mêmes, l'entrepreneur peut
          ajouter des frais de déplacement pour le transport des équipements du magasin jusqu'au
          chantier et des coûts liés à la main d’œuvre. Pensez à transmettre les références des
          articles que vous avez repérés à l’entrepreneur, vous bénéficierez ainsi de tarifs
          préférentiels, de meilleures garanties et de toutes les assurances&nbsp;!
        </>
      ),
      icon: {
        position: 'left',
        element: <QuotePicto />,
        size: '2xl',
      },
    },
    {
      title: 'Une TVA plus avantageuse',
      content: (
        <>
          Contrairement aux particuliers qui bénéficient d’une TVA à 20%, l’entrepreneur aura au
          minimum droit à une TVA à 10% sur l’achat de son matériel, TVA pouvant aller jusqu’à 5,5%
          sur la pose des matériaux visant à économiser l'énergie.
        </>
      ),
      icon: {
        position: 'right',
        element: <CalculatePicto />,
        size: '2xl',
      },
    },
    {
      title: 'Une charge mentale allégée',
      content: (
        <>
          En passant par votre entrepreneur, vous bénéficiez d'une pré-sélection de matériaux et
          équipements de qualité (plusieurs niveaux de gamme vous seront proposés), qui seront
          livrés directement sur le chantier. Vous gagnez donc du temps et ne gaspillez pas
          d'énergie inutilement&nbsp;!
        </>
      ),
      icon: {
        position: 'left',
        element: <Brand />,
        size: '2xl',
      },
    },
    {
      title: 'Une réduction des risques',
      content: (
        <>
          En cas de défaillance des équipements que vous avez achetés vous-même, l'entreprise n’a
          aucune obligation de refaire les travaux et vous devrez gérer en autonomie les litiges
          liés à ces derniers.
        </>
      ),
      icon: {
        position: 'right',
        element: <WorkmenPicto />,
        size: '2xl',
      },
    },
  ],
};

export const paiement: ArticleConfig[] = [
  {
    title: 'Votre compte séquestre',
    content: (
      <>
        Les clients du secteur du BTP redoutent souvent de devoir payer avant la fin des travaux ou
        de faire face à un abandon de chantier malgré les paiements. Pour remédier à cela, hemea met
        à disposition un compte séquestre Lemon Way (en partenariat avec BNP Paribas et sous la
        supervision de l’ACPR - Banque de France) assurant la sécurité de vos fonds.
        <br />
        <br />
        Vos fonds demeurent sécurisés sur ce compte et ne seront débloqués et transférés à
        l'entreprise qu'après votre confirmation de l'avancement du chantier, vous garantissant
        ainsi une tranquillité d'esprit.
        <br />
        <br />
        Afin de consulter la situation de votre compté séquestre ainsi que l’historique des
        différents mouvements, rendez-vous dans la partie Paiement de votre espace client.
        <br />
      </>
    ),
    icon: {
      position: 'left',
      element: <CalculatePicto />,
      size: '6xl',
    },
  },
  {
    title: <>Échéancier des appels de provision</>,
    content: (
      <>
        Voici le calendrier des provisions à effectuer tout au long du chantier, sur votre compte
        séquestre :
        <ul>
          <li>À la signature : 40% des travaux + les frais hemea</li>
          <li>Date de début + 4 jours : 30%</li>
          <li>Mi-chantier - 10 jours : 30%</li>
        </ul>
        Il est crucial d'effectuer le premier paiement dès la signature afin de permettre à
        l'entreprise de bien préparer votre chantier: achat des matériaux, passage des commandes ...
        Tout retard de paiement entraînera un décalage de la date de début du chantier.
        <br />
        <br />
        Si le début de votre chantier est prévu pour une date significativement postérieure à la
        signature du contrat, ou s'il est conditionné à la finalisation de l'acte de vente, la
        première provision sur le compte séquestre pourra être réalisée ultérieurement, idéalement
        jusqu'à 3 semaines avant le début effectif des travaux.
        <br />
        <br />
        En cas d'absence de provisions sur votre compte séquestre, des rappels par email seront
        envoyés tous les 5 jours jusqu'à réception du virement.
      </>
    ),
    icon: {
      position: 'right',
      element: <SearchCalendarPicto />,
      size: '6xl',
    },
  },
  {
    title: <>Comment provisionner mon compte séquestre ?</>,
    content: (
      <>
        Vous avez reçu par email le RIB unique associé à votre compte séquestre, avec les
        informations nécessaires pour effectuer le virement, y compris la référence de l'ordre de
        virement.
        <br />
        <br />
        Pour réaliser ce virement, nous vous recommandons de contacter votre banque par téléphone,
        car les montants importants peuvent parfois être bloqués. Il est probable que votre banque
        vous demande la facture d'acompte, que vous trouverez en pièce jointe de l’email ainsi que
        sur votre espace client.
        <br />
        <br />
        Si vous avez des questions ou rencontrez des problèmes, n'hésitez pas à contacter votre
        Travaux Planner ou votre chargé d'accompagnement travaux.
        <br />
      </>
    ),
    icon: {
      position: 'left',
      element: <QuotePicto />,
      size: '6xl',
    },
  },
];

export const prix: { introduction: React.ReactElement; articles: ArticleConfig[] } = {
  introduction: <>Qu’est-ce qui influence le prix des travaux&nbsp;?</>,
  articles: [
    {
      title: 'Budget',
      content: (
        <>
          Connaître votre budget nous permet de cibler votre demande et de vous faire gagner du
          temps.
          <br />
          Les prestations, gammes de matériaux et finitions seront proposées en fonction de votre
          enveloppe budgétaire.
        </>
      ),
      icon: {
        position: 'left',
        element: <AddDocPicto />,
        size: '3xl',
      },
    },
    {
      title: 'La gamme de prix',
      content: (
        <>
          Pour s’adapter au budget et aux attentes de chacun de ses clients, hemea a développé
          plusieurs gammes&nbsp;:
          <ArticleListItems>
            <li>
              Haut de gamme / Exclusif : beaucoup de temps et de savoir-faire pour réaliser vos
              exigences dans les moindres détails.
            </li>
            <li>
              Gamme supérieure / Premium : une mise en jeu des couleurs, des motifs et des textures
              pour convenir à votre style.
            </li>
            <li>
              Milieu de gamme / Confort : un juste compromis entre du fonctionnel et des finitions
              de qualité.
            </li>
            <li>
              Entrée de gamme / Primo : de l’efficacité et rapidité dans l’exécution de vos travaux,
              idéal pour l’investissement locatif.
            </li>
          </ArticleListItems>
        </>
      ),
      icon: {
        position: 'right',
        element: <CalculatePicto />,
        size: '3xl',
      },
    },
    {
      title: 'Autres facteurs',
      content: (
        <>
          Voici une liste non exhaustive des facteurs qui peuvent influer sur le prix d’un
          devis&nbsp;:
          <ArticleListItems>
            <li>la gamme de matériaux et de travaux choisie</li>
            <li>la réputation de l’entreprise et le niveau de qualité de son travail</li>
            <li>
              la zone géographique du chantier (les travaux en région parisienne sont plus coûteux)
            </li>
            <li>
              l’accessibilité générale du logement et sa potentielle occupation (le temps nécessaire
              à la protection des meubles sera facturé)
            </li>
            <li>
              le type d’habitat, son ancienneté et la taille du chantier (économie d’échelle){' '}
            </li>
            <li>les réductions éventuelles de l’entrepreneur chez ses fournisseurs</li>
            <br />
          </ArticleListItems>
          <strong>Votre bien est unique, le prix de vos travaux l’est aussi&nbsp;!</strong>
        </>
      ),
      icon: {
        position: 'left',
        element: <QuotePicto />,
        size: '3xl',
      },
    },
  ],
};

export const demarrage: { introduction: React.ReactElement; articles: ArticleConfig[] } = {
  introduction: <>Que faut-il vérifier avant le démarrage des travaux&nbsp;?</>,
  articles: [
    {
      title: 'Se faire confirmer la faisabilité du projet',
      content: (
        <>
          Nous vous conseillons de questionner le professionnel concernant son ressenti global et
          d'approfondir avec lui les points qui pourraient lui paraître difficiles à réaliser pour
          votre projet. Déterminer les contraintes liées à vos travaux peut vous aider à prendre des
          décisions cruciales.
        </>
      ),
      icon: {
        position: 'right',
        element: <ListPicto />,
        size: '6xl',
      },
    },
    {
      title: 'Demander des changements pendant les travaux',
      content: (
        <>
          Une fois le début et la fin de chantier déterminés, vous pouvez établir un planning
          prévisionnel de votre projet, détaillant plus précisément les différentes étapes des
          travaux. Vous aurez alors l’occasion de prévenir le professionnel de certaines
          particularités et de prévoir des visites régulières sur le chantier.
        </>
      ),
      icon: {
        position: 'left',
        element: <HousePicto />,
        size: '6xl',
      },
    },
    {
      title: "Identifier l'interlocuteur principal de votre chantier",
      content: (
        <>
          Il est important de déterminer qui sera votre interlocuteur privilégié sur le chantier
          afin de savoir qui joindre en cas de nécessité. Il est judicieux de demander les
          coordonnées du chef d’équipe en charge de votre projet.
        </>
      ),
      icon: {
        position: 'right',
        element: <AdvisorsPicto />,
        size: '6xl',
      },
    },
    {
      title: 'Sélectionner et acheter ses fournitures',
      content: (
        <>
          Le choix des fournitures doit être effectué dans les meilleurs délais afin d’anticiper
          toute pénurie ou retard de livraison. N’hésitez pas à demander à votre entrepreneur la
          confirmation de votre commande et à échanger sur le sujet dans le groupe WhatsApp.
        </>
      ),
      icon: {
        position: 'left',
        element: <ProjectPicto />,
        size: '6xl',
      },
    },
    {
      title: 'S’assurer de la durée du chantier',
      content: (
        <>
          Réfléchissez à la date à laquelle vous souhaiteriez idéalement débuter ces travaux, afin
          que le professionnel puisse s'assurer de sa disponibilité.
          <br />
          La durée du chantier va principalement dépendre de deux facteurs&nbsp;:
          <ArticleListItems>
            <li>
              l’ampleur des travaux : l'entrepreneur doit avoir une idée précise des corps d'état à
              solliciter.
            </li>
            <li>
              l'agenda du professionnel : les entreprises ont souvent plusieurs chantiers à réaliser
              en même temps.
            </li>
          </ArticleListItems>
        </>
      ),
      icon: {
        position: 'right',
        element: <CalendarPicto />,
        size: '6xl',
      },
    },
    {
      title: 'Demander des changements pendant les travaux',
      content: (
        <>
          Renseignez-vous sur la façon dont il est possible de communiquer une demande de changement
          à l’entreprise responsable de vos travaux. Cependant, l’entrepreneur peut se réserver le
          droit de les refuser, notamment si le chantier dépasse les délais prévus au départ et que
          son planning ne le lui permet plus.
        </>
      ),
      icon: {
        position: 'left',
        element: <HousePicto />,
        size: '6xl',
      },
    },
    {
      title: 'Vérifier que le chantier sera bien protégé',
      content: (
        <>
          Il est préférable de valider avec l’entreprise qui réalisera vos travaux que toutes les
          pièces concernées par les travaux seront protégées et de quelle façon. Renseignez-vous
          également sur la protection des éventuelles parties communes offrant l’accès à votre bien
          si vous habitez dans une copropriété.
        </>
      ),
      icon: {
        position: 'right',
        element: <ChantierPicto />,
        size: '6xl',
      },
    },
    {
      title: 'Provisionner le début du chantier',
      content: (
        <>
          Pour commencer le chantier dans les meilleures conditions possibles, 40% du compte
          séquestre doivent être provisionnés trois semaines avant le début du chantier. Cette
          provision est indiquée sur votre devis.
        </>
      ),
      icon: {
        position: 'left',
        element: <ProjectPicto />,
        size: '6xl',
      },
    },
  ],
};

export const suivi: { articles: ArticleConfig[] } = {
  articles: [
    {
      title: 'Valider l’avancement du chantier et débloquer des fonds',
      content: (
        <>
          Suite à la signature du devis, il est essentiel de débloquer 40% du montant total pour
          permettre de débuter le chantier dans les temps. Cette somme est nécessaire à l'entreprise
          pour lancer les différentes commandes et préparatifs.
          <br />
          <br />
          Lorsque le chantier atteint un certain stade, l'entreprise demande le déblocage de fonds
          supplémentaires. Elle renseigne alors le pourcentage d'avancement de chacune des
          prestations, ainsi que l'état de réception des fournitures*.
          <br />
          <br />
          Vous devez alors vérifier chacune des prestations, confirmer leur état d'avancement ou
          apporter des ajustements si nécessaire. Le montant à débloquer est calculé en fonction de
          cet avancement, déduction faite de la somme déjà libérée.
          <br />
          <br />
          <span className="italic">
            *Le déblocage de fonds par état d’avancement est activé pour les projets signés après le
            04/09/2023.
          </span>
        </>
      ),
    },
    {
      title: '1. Evaluer l’état d’avancement de chacune des prestation',
      content: (
        <>
          Pour vérifier l'état d'avancement des différentes prestations, accédez à votre espace de
          paiement et cliquez sur "<strong>Débloquer des fonds</strong>".
          <br />
          <br />
          Vous devez examiner chacune des prestations et approuver ou contester l'état d'avancement
          fourni par l'entreprise.
        </>
      ),
      image: {
        position: 'right',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/guide_suivi_4.png`),
          alt: 'photo chambre',
        },
        withPadding: true,
        isLarge: true,
      },
    },
    {
      title: '2. Valider l’avancement d’une prestation',
      content: (
        <>
          Pour approuver l'avancement d'une prestation tel que renseigné par l'entreprise, cliquez
          simplement sur la petite coche verte. Cela marquera la prestation en vert et contribuera
          au calcul du montant à débloquer.
        </>
      ),
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/guide_suivi_1.png`),
          alt: 'photo chambre',
        },
        withPadding: true,
        isLarge: true,
      },
    },
    {
      title: '3. Modifier ou refuser l’avancement d’une prestation',
      content: (
        <>
          Si vous n'êtes pas en accord avec l'état d'avancement d'une prestation, vous avez la
          possibilité de la refuser ou de la modifier.
          <br />
          <br />
          Pour apporter des modifications, cliquez sur l'icône en forme de croix rouge. Cela ouvrira
          une nouvelle fenêtre où vous pourrez effectuer les ajustements souhaités.
          <br />
          <br />
          Pour confirmer ces modifications, il est impératif de fournir une explication du désaccord
          dans le champ prévu à cet effet.
        </>
      ),
      image: {
        position: 'right',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/guide_suivi_3.png`),
          alt: 'photo chambre',
        },
        withPadding: true,
        isLarge: true,
      },
    },
    {
      title: '4. Confirmer l’état d’avancement et libérez les fonds',
      content: (
        <>
          Après avoir vérifié toutes les prestations, cliquez sur "
          <strong>Valider l'avancement</strong>". Vous obtiendrez alors le montant à libérer en
          fonction de l'état d'avancement confirmé précédemment. Si l'avancement est inférieur au
          montant déjà libéré, aucun fonds ne sera débloqué, et cela vous sera notifié.
          <br />
          <br />
          Cliquez sur "<strong>Confirmer</strong>" pour enregistrer l'état d'avancement et valider
          les fonds éventuellement à libérer.
        </>
      ),
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/guide_suivi_2.png`),
          alt: 'photo chambre',
        },
        withPadding: true,
        isLarge: true,
      },
    },
    {
      title: 'Si vous avez choisi l’offre Expertise',
      content: (
        <>
          Si vous avez opté pour l'<i>Offre Expertise</i> avec un suivi physique du chantier par un
          Travaux Planner, ce dernier vérifiera en amont l'état d'avancement des prestations
          validées par l'entreprise. Il ajustera ce qui ne concorde pas avec la réalité du chantier.
          <br />
          <br />
          Les autres étapes de validation demeurent identiques à celles évoquées précédemment, mais
          incluent une étape intermédiaire de vérification par le Travaux Planner.
        </>
      ),
    },
  ],
};

export const reception: { introduction: string; articles: ArticleConfig[] } = {
  introduction: 'Tout ce que vous devez savoir sur la réception de votre chantier',
  articles: [
    {
      title: <>Réception des travaux : en quoi ça consiste&nbsp;?</>,
      content: (
        <>
          C’est l'ultime étape d'un chantier de rénovation ou de construction pendant laquelle le
          responsable du chantier doit constater, en présence des différents entrepreneurs, que les
          travaux sont bien achevés et que ceux-ci ont été réalisés conformément au contrat.
          <br />
          La réception des travaux est vivement recommandée et elle se révèle indispensable pour
          pouvoir bénéficier des garanties légales évoquées précédemment.
        </>
      ),
      icon: {
        position: 'left',
        element: <InfoCircleOutline />,
        size: '6xl',
      },
    },
    {
      title: <>Combien de temps prévoir pour la visite de réception&nbsp;?</>,
      content: (
        <>
          La durée de la réception des travaux dépend en premier lieu de la qualité du suivi de
          votre chantier&nbsp;:
          <br />
          <br />
          <strong>Vous avez fait un suivi régulier et rigoureux pendant vos travaux&nbsp;?</strong>
          <br />
          La réception du chantier devrait être rapide (1 à 2 heures maximum). Les principaux
          problèmes ayant probablement été résolus au fur et à mesure de l'avancement des travaux,
          la réception sera une formalité plutôt qu’un contrôle exhaustif.
          <br />
          <br />
          <strong>Vous n’avez pas fait de suivi assidu&nbsp;?</strong>
          <br />
          Il est probable que des questions / problèmes remontent lors de cet état des lieux final
          du chantier et créent des incompréhensions et tensions à ce moment-là, prévoyez une
          demi-journée.
        </>
      ),
      icon: {
        position: 'right',
        element: <Clock />,
        size: '6xl',
      },
    },
    {
      title: <>Qu’est-ce que le procès-verbal de réception&nbsp;?</>,
      content: (
        <>
          Le procès-verbal de réception des travaux par les différents acteurs est un document qui
          authentifie la bonne réception des travaux et marque le début des garanties légales
          (parfait achèvement, biennale et décennale).
        </>
      ),
      icon: {
        position: 'left',
        element: <RealEstateOutline />,
        size: '6xl',
      },
      link: {
        position: 'right',
        element: (
          <Link
            href={receptionWebsiteUrl}
            target="_blank"
            rel="noopener"
            icon={<OpenTab />}
            className="text-b1"
            onClick={() => trackRedirectionLinkEvent('la reception')}
          >
            En savoir plus
          </Link>
        ),
      },
    },
    {
      title: <>Quels équipements prévoir&nbsp;?</>,
      content: (
        <>
          Il est vivement conseillé d'apporter les équipements suivants&nbsp;:
          <br />
          <br />
          <ArticleListItems>
            <li>
              Un appareil photo ou un smartphone de qualité : pour conserver des preuves en cas de
              réserves.
            </li>
            <li>
              Une lampe torche ou le flash de votre mobile : pour repérer les éventuels défauts dans
              une zone sombre.
            </li>
            <li>
              Un appareil électrique et une ampoule : pour tester le bon fonctionnement de
              l’éléctricité.
            </li>
            <li>
              Un niveau à bulle ou laser : pour vérifier la planéité des surfaces en cas de doute.
            </li>

            <li>
              L'ensemble des documents contractuels liés à la construction ou à la rénovation (devis
              de l'entrepreneur ou contrat du constructeur ainsi que les plans du projet).
            </li>
          </ArticleListItems>
        </>
      ),
      icon: {
        position: 'right',
        element: <Construction />,
        size: '6xl',
      },
    },
    {
      title: <>Vous avez besoin d’être accompagné par hemea&nbsp;?</>,
      content: (
        <>
          Vous avez choisi l’offre d'accompagnement Support et souhaitez être rassurés pendant la
          réception de votre chantier&nbsp;?
          <br />
          <br />
          Pour 250€, un Travaux Planner hemea peut se rendre sur place pour vous accompagner dans
          cette dernière étape cruciale de votre rénovation. Il pourra alors vous apporter son
          expertise et vous conseiller, aucun détail n’échappe à son oeil d’expert&nbsp;!
          <br />
          Si cette offre vous intéresse, veuillez contacter votre chargé d’accompagnement travaux.
        </>
      ),
      icon: {
        position: 'left',
        element: <Assistance />,
        size: '6xl',
      },
    },
  ],
};

export const servicesHemea: { introduction: React.ReactElement; articles: ArticleConfig[] } = {
  introduction: (
    <Card>
      Pour l’ensemble des projets, quelle que soit l’offre d’accompagnement choisie, bénéficiez des
      nombreux services hemea pour que votre projet se déroule en toute sérénité. Ces services hemea
      vous sont facturés 3% du montant TTC de vos travaux et comprennent les éléments
      suivants&nbsp;:
    </Card>
  ),
  articles: [
    {
      title: 'La protection hemea',
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/protection-hemea.png`),
          alt: 'photo chambre',
        },
      },
      content: (
        <>
          La <span className="font-bold">protection hemea est une exclusivité sur le marché</span>{' '}
          des projets d'architecture et de rénovation. L'accompagnement hemea inclut cette garantie
          exclusive pour que méfiance et travaux ne soient plus jamais associés.
          <br /> <br />
          Grâce à la protection hemea, en cas d'imprévu, hemea vous couvre à hauteur de 25% du
          montant des travaux pour un montant maximum de 50 000€&nbsp;:{' '}
          <span className="font-bold">à situation exceptionnelle, protection idéale</span>.
          <div className="my-xs italic">
            Service valable pour toute demande effectuée à partir du 01/04/2023
          </div>
          <div className="text-right">
            <Link href={protectionWebsiteUrl} icon={<OpenTab />} target="_blank">
              En savoir plus sur la protection hemea
            </Link>
          </div>
        </>
      ),
    },
    {
      title: 'Les pénalités de retard',
      image: {
        position: 'right',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/penalites-retard.jpg`),
          alt: 'photo cuisine',
        },
      },
      content: (
        <>
          Nos contrats précisent la date de début et la date de fin de chaque chantier.
          <br />
          <br />
          La signature de votre devis en ligne inclut un engagement contractuel de la part de{' '}
          <span className="font-bold">l'entreprise</span> qui{' '}
          <span className="font-bold">s’engage à vous reverser des pénalités en cas de retard</span>
          , sur la base de 1/1000 du montant € HT du devis par jour de retard. Le montant de la
          pénalité est limité à 5% du montant HT du devis.
          <br /> <br />
          Avec hemea, c’est l’assurance de travaux terminés dans les temps.
        </>
      ),
    },
    {
      title: 'La sélection des entreprises',
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/selection-entreprises.jpg`),
          alt: 'photo cuisine',
        },
      },
      content: (
        <>
          Chaque entrepreneur labellisé hemea doit répondre à des{' '}
          <span className="font-bold">critères stricts</span>. Nous les rencontrons tous
          personnellement, nous vérifions leur structure et leurs{' '}
          <span className="font-bold">assurances</span>. Nous nous assurons qu’ils ont plus de 2 ans
          d'ancienneté et nous contactons leurs anciens clients.
          <br /> <br />
          Enfin, vous ne le savez peut-être pas, mais nos entrepreneurs ont tout intérêt à
          travailler avec nous, car non seulement nous leur apportons du travail, mais nous leur
          facilitons aussi la vie avec des outils spécialement imaginés pour eux. Bref, une relation
          où chacun gagne.
        </>
      ),
    },
    {
      title: 'Le compte séquestre',
      image: {
        position: 'right',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/service-hemea-compte-sequestre.jpg`),
          alt: 'photo cuisine',
        },
      },
      content: (
        <>
          Un des écueils auxquels sont confrontés les clients d'entreprises du BTP&nbsp;: payer les
          travaux avant que le chantier ne soit intégralement terminé, voire pire, connaître un
          abandon de chantier alors que des avances ont été payées.
          <br /> <br />
          C'est pourquoi hemea vous met à disposition{' '}
          <span className="font-bold">un compte séquestre</span> LemonWay (compte BNP Paribas
          accrédité auprès de l’ACPR - Banque de France) pour éviter ces risques.
          <br /> <br />
          Votre argent est versé aux entrepreneurs{' '}
          <span className="font-bold">uniquement lorsque vous avez validé</span> l’avancée et la
          conformité de vos travaux.
        </>
      ),
    },
    {
      title: 'L’accompagnement de votre Chef de Projet',
      image: {
        position: 'left',
        imageProps: {
          url: buildImgixURL(`${guidesImgixUrl}/conseils-cdp.jpg`),
          alt: 'photo cuisine',
        },
      },
      content: (
        <>
          Votre Chef de Projet dédié vous accompagne tout le long de la phase d’étude de votre
          projet afin de vous conseiller et de vous aider sur différents sujets&nbsp;:
          <ul className="mb-0 mt-sm pl-md">
            <li>Estimation détaillée du montant de vos travaux</li>
            <li>Conseils sur la faisabilité de votre projet</li>
            <li>Sélection des architectes et entrepreneurs les plus adaptés à votre projet</li>
            <li>Aide à la négociation et la modification des devis réalisés par les entreprises</li>
            <li>Conseil sur le choix de l’entreprise qui va réaliser vos travaux</li>
          </ul>
        </>
      ),
    },
  ],
};
