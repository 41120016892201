import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import ProjectPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectPicto.svg?react';
import { LayoutGrid } from '@travauxlib/shared/src/components/DesignSystem/components/Layout';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { useTruncate } from '@travauxlib/shared/src/hooks/useTruncate';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { StructureCard } from 'components/Card';

import { DeleteEstimationModal } from './components/DeleteEstimationModal';
import { EditEstimationInfos } from './components/EditEstimationInfos';

import { Estimation } from '../../types';
import { useIsEstimateurPartner } from '../../utils/paths';

type Props = {
  estimations: Estimation[];
  submitEstimation: (estimation: Estimation) => Promise<unknown>;
  deleteEstimation: (estimationUuid: string) => void;
};

const NewEstimationButton = (): React.ReactElement => {
  const isMobileOrTablet = useIsTabletOrMobile();
  return isMobileOrTablet ? (
    <IconButtonLink variant="primary" to="new">
      <PlusSymbol />
    </IconButtonLink>
  ) : (
    <ButtonLink to="new" leftIcon={<PlusSymbol />}>
      Nouveau projet
    </ButtonLink>
  );
};

export const EstimationList: React.FC<Props> = ({
  estimations,
  deleteEstimation,
  submitEstimation,
}) => {
  const truncateWordsWithTooltip = useTruncate();
  const [estimationToEdit, setEstimationToEdit] = useState<Estimation | undefined>(undefined);
  const [estimationUuid, setEstimationUuid] = useState<string | undefined>(undefined);
  const isEstimateurPartner = useIsEstimateurPartner();

  return (
    <LayoutGrid className="py-xl">
      <div className="col-span-full md-desktop:col-span-10 md-desktop:col-start-2">
        <div className="mb-xl">
          <div className="flex items-center justify-between">
            <div className="text-h4 font-bold md-desktop:text-h3">Estimateur en ligne</div>
            <div className="text-center">
              <NewEstimationButton />
            </div>
          </div>
        </div>
        <StructureCard>
          <div className="text-h5 font-bold">Mes projets</div>
          {estimations.length > 0 ? (
            <Table<Estimation>
              columnConfigs={[
                {
                  name: 'Nom du projet',
                  columnKey: 'title',
                  renderValue: (_, estimation) => (
                    <Link to={`${estimation.uuid}/result`}>
                      {truncateWordsWithTooltip({
                        value: `${
                          estimation.title || `${estimation.firstName} ${estimation.lastName}`
                        }`,
                        maxCharsForScreenSize: {
                          'md-desktop': 20,
                          'lg-desktop': 30,
                        },
                      })}
                    </Link>
                  ),
                },
                {
                  name: 'Adresse',
                  columnKey: 'googleMapsAddress',
                  renderValue: (_, estimation) => (
                    <span>
                      {estimation.googleMapsAddress && (
                        <>
                          {truncateWordsWithTooltip({
                            value: estimation.googleMapsAddress.formatted_address,
                            maxCharsForScreenSize: {
                              'md-desktop': 20,
                              'lg-desktop': 30,
                              'giga-screen': 45,
                            },
                          })}
                        </>
                      )}
                    </span>
                  ),
                },
                ...(isEstimateurPartner
                  ? [
                      {
                        name: 'Email',
                        columnKey: 'email',
                      },
                      {
                        name: 'Téléphone',
                        columnKey: 'phoneNumber',
                      },
                    ]
                  : ([] as any)),
                {
                  name: 'Date de création',
                  columnKey: 'createdAt',
                  renderValue: (createdAt: string) => <>{formatFrenchDate(createdAt)}</>,
                },
                {
                  name: 'Prix TTC',
                  columnKey: 'result',
                  renderValue: (_, estimation) => (
                    <>
                      {estimation.result?.totalTTC !== undefined && (
                        <EURCurrency amount={estimation.result?.totalTTC} />
                      )}
                    </>
                  ),
                },
                {
                  name: 'Surface',
                  columnKey: 'context',
                  renderValue: (_, estimation) => (
                    <>
                      {estimation.context?.surfaceTotale !== undefined && (
                        <div>{estimation.context.surfaceTotale} m²</div>
                      )}
                    </>
                  ),
                },
              ]}
              rowActions={item => [
                ...(isEstimateurPartner
                  ? [
                      {
                        label: 'Modifier',
                        action: () => setEstimationToEdit(item),
                      },
                    ]
                  : []),
                {
                  label: 'Dupliquer',
                  action: () => submitEstimation({ ...item, uuid: uuidV4() }),
                },
                {
                  label: 'Supprimer',
                  action: () => setEstimationUuid(item.uuid),
                },
              ]}
              defaultSort={{ columnKey: 'createdAt', order: 'desc' }}
              items={estimations}
            />
          ) : (
            <div className="mt-xl text-center">
              <div className="mb-md">
                <ProjectPicto width={80} height={80} />
              </div>
              <div className="text-b1 font-bold">Aucun projet trouvé</div>
              <div className="mt-xxs text-b2">
                Vous pouvez créer un projet avec le bouton “Nouveau projet”
              </div>
            </div>
          )}
        </StructureCard>

        <DeleteEstimationModal
          estimationUuid={estimationUuid}
          handleDeleteEstimation={deleteEstimation}
          handleClose={() => setEstimationUuid(undefined)}
        />
        <EditEstimationInfos
          estimation={estimationToEdit}
          handleUpdateEstimation={submitEstimation}
          handleClose={() => setEstimationToEdit(undefined)}
        />
      </div>
    </LayoutGrid>
  );
};
