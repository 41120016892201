import React from 'react';

type Props = {
  header: React.ReactElement;
  sidebar: React.ReactElement | null;
  children: React.ReactNode;
  footer?: React.ReactElement | null;
  popin?: React.ReactNode;
};

export const DocumentDisplayLayout: React.FC<Props> = ({
  header,
  sidebar,
  footer,
  children,
  popin,
}) => (
  <div className="flex flex-col h-full">
    {header}
    <div className="grow flex overflow-hidden">
      {/* The w-0 is pretty weird but it works as flex-grow will do the job. Otherwise,
       * "A flex item cannot be smaller than the size of its content along the main axis". As we can have huge pdf inside the content of this page
       * this can cause troubles.
       */}
      <div className="grow w-0">{children}</div>
      <div className="w-[20.5rem] shrink-0 hidden sm-desktop:block">{sidebar}</div>
      {popin && (
        <div className="w-[512px] z-50 shrink-0 absolute right-0 top-0 bg-neutral-0 h-screen shadow-xl">
          {popin}
        </div>
      )}
    </div>
    {footer}
  </div>
);
