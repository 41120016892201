import React from 'react';

import { Form } from 'react-final-form';

import { ButtonGroupField } from '@travauxlib/shared/src/components/DesignSystem/components/ButtonGroup/Field';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { required } from '@travauxlib/shared/src/utils/form';

import { QuestionProps } from 'features/Estimateur/types';

import { Title } from '../QuestionTitle';

type FormValues = {
  existant?: string;
  souhaite?: string;
  answerType?: string;
};

export const Chauffage = ({ submitAnswer, initialValues }: QuestionProps): React.ReactElement => (
  <Form<FormValues> initialValues={initialValues} onSubmit={submitAnswer}>
    {({ values: { existant, souhaite }, handleSubmit, valid, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="mb-xl">
          <Title title="Chauffage" />
          <div className="mb-md">
            <FormLabel label="Quel est le chauffage existant ?" />
            <ButtonGroupField
              validate={required}
              name="existant"
              options={[
                { label: 'Gaz', value: 'gaz' },
                { label: 'Électrique', value: 'electrique' },
                { label: 'Fioul', value: 'fioul' },
                { label: 'Aucun', value: 'aucun' },
              ]}
            />
          </div>
          <div className="mb-md">
            <FormLabel label="Quel est le chauffage souhaité ?" />
            <ButtonGroupField
              validate={required}
              name="souhaite"
              options={[
                { label: 'Électrique', value: 'electrique' },
                { label: 'Gaz', value: 'gaz' },
              ]}
            />
          </div>
          {existant === 'fioul' ||
            (existant === 'gaz' && souhaite === 'gaz' && (
              <div className="mb-md">
                <FormLabel label="Souhaitez-vous conserver les radiateurs" />
                <ButtonGroupField
                  validate={required}
                  name="keepRadiateurs"
                  options={[
                    { label: 'Oui, converver les radiateurs', value: true },
                    {
                      label: 'Non, poser de nouveaux radiateurs',
                      value: false,
                    },
                  ]}
                />
              </div>
            ))}
        </div>
        <div className="text-center">
          <Button type="submit" disabled={!valid || submitting} loading={submitting}>
            Valider
          </Button>
        </div>
      </form>
    )}
  </Form>
);
