import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

export const getServiceHemeaDetails = (
  montantProtectionHemea: number,
): Array<string | React.ReactElement> => [
  <>
    Protection hemea : Couverture de vos travaux à hauteur de{' '}
    <EURCurrency amount={montantProtectionHemea} />{' '}
    <Link href={`${APP_CONFIG.wwwURL}/fr/offres/protection-hemea`} inline target="_blank">
      En savoir plus sur la protection
    </Link>
  </>,
  'Accompagnement personnalisé par votre chef de projet travaux',
  'Espace client digital pour suivre l’avancé de votre chantier ainsi que l’ensemble des flux financiers',
  'Pénalités en cas de retard sur votre chantier',
  'Accès aux entrepreneurs labellisés hemea : audit et contrôle continu de la qualité des travaux',
];
