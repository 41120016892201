import React from 'react';

export type Props = {
  icon: React.ReactElement;
  title: string;
  description: string;
};

export const ReassuranceItem: React.FC<Props> = ({ icon, title, description }) => (
  <div className="flex sm-desktop:flex-col gap-xs items-center">
    <div className="shrink-0 text-primary-400 w-lg h-lg sm-desktop:w-xl sm-desktop:h-xl">
      {icon}
    </div>
    <div className="flex flex-col sm-desktop:text-center text-b2">
      <div className="font-bold">{title}</div>
      <div className="text-neutral-600">{description}</div>
    </div>
  </div>
);
