import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import UnCheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/UnCheckCircle.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { TypeSuivi } from '@travauxlib/shared/src/types';

import { useCurrentDevis } from '../../CurrentDevisContext';

type Props = {
  title: string;
  price: React.ReactElement | string;
  subPrice?: string;
  detailRows: Array<string | React.ReactElement>;
  children?: React.FC<{ disabled: boolean }>;
  linkUrl?: string;
  isSelected?: boolean;
  typeSuivi?: TypeSuivi;
  isTS: boolean;
  hasBeenSigned: boolean;
};

export const DetailsCard: React.FC<Props> = ({
  title,
  price,
  subPrice,
  detailRows,
  children,
  linkUrl,
  isSelected,
  typeSuivi,
  isTS,
  hasBeenSigned,
}) => {
  const { setTypeSuivi } = useCurrentDevis();
  const isReadOnly = isTS || hasBeenSigned;
  const isReadOnlyNotSelected = !isSelected && isReadOnly;

  return (
    <Card
      className={classNames({
        'bg-neutral-100 text-neutral-300': isReadOnlyNotSelected,
        'border-primary border-2': isSelected,
        'cursor-pointer': !isSelected && !isReadOnly,
      })}
      state="outlined"
      onClick={() => !isReadOnly && typeSuivi && setTypeSuivi(typeSuivi)}
      role="section"
      title={title}
    >
      <>
        <div
          className={classNames('flex justify-between items-center', {
            'mb-md': !subPrice,
          })}
        >
          <div className="flex items-center">
            {isSelected ? (
              <div className="flex w-lg h-lg text-primary mr-xs">
                {title === 'Service hemea' ? <InfoCircle /> : <CheckCircle />}
              </div>
            ) : (
              <div className="flex w-lg h-lg text-neutral-300 mr-xs">
                <UnCheckCircle />
              </div>
            )}
            <div className="font-bold mr-xxs">{title}</div>
            <div
              className={classNames(
                'text-b2',
                isReadOnlyNotSelected ? 'text-neutral-300' : 'text-neutral-700',
              )}
            >
              TTC
            </div>
          </div>
          <div className="font-bold">{price}</div>
        </div>
        {subPrice && (
          <div
            className={classNames(
              'text-right text-b2 mb-md',
              isReadOnlyNotSelected ? 'text-neutral-300' : 'text-neutral-700',
            )}
          >
            {subPrice}
          </div>
        )}
        {children?.({ disabled: isReadOnlyNotSelected })}
        <ul
          className={classNames(
            'text-b2 pl-md',
            linkUrl ? 'mb-xs' : 'mb-0',
            isReadOnlyNotSelected ? 'text-neutral-300' : 'text-neutral-700',
          )}
        >
          {detailRows.map(detailRow => (
            <li className="mb-xxs last:mb-0" key={detailRow.toString()}>
              {detailRow}
            </li>
          ))}
        </ul>
        {linkUrl && (
          <Link href={linkUrl} size="md" inline disabled={isReadOnlyNotSelected}>
            Récapitulatif de l'offre ici
          </Link>
        )}
      </>
    </Card>
  );
};
