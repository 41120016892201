import React from 'react';

import { useLocation } from 'react-router-dom';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';

export type CrumbPage = {
  name: string;
  url: string;
};

type BreadcrumbProps = {
  crumbs: CrumbPage[];
  backLinkLabel?: string;
  icon?: React.ReactElement;
};

const MAX_STEPS: number = 7;

const ParentItem: React.FC<{
  crumb: CrumbPage;
  icon?: React.ReactElement;
}> = ({ crumb, icon }) => (
  <li className="mr-xxs flex items-center">
    <Link
      className="mr-xxs flex items-center !text-neutral-600 active:!text-neutral-800"
      to={crumb.url}
    >
      {icon && <div className="h-xmd w-xmd flex items-center mr-xxs">{icon}</div>}
      <span>{crumb.name}</span>
    </Link>
    <div className="flex items-center">
      <ChevronRight className="h-md w-md text-neutral-600" />
    </div>
  </li>
);

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  crumbs = [], // = parents + current page which is the last one
  backLinkLabel = 'Retour',
  icon,
}) => {
  const currentPage = crumbs[crumbs.length - 1];
  const parentPages = crumbs.slice(0, crumbs.length - 1);
  const previousParent = crumbs[crumbs.length - 2];
  const firstParent = crumbs[0];

  const location = useLocation();

  if (location.pathname !== currentPage.url) {
    return null;
  }

  return (
    <div className="flex items-center h-lg text-b2">
      <Link
        to={previousParent.url}
        className="flex max-w-full items-center mr-xs pr-xs rounded-md
          !text-neutral-800 font-bold !no-underline
          hover:bg-neutral-200
          active:bg-neutral-300 active:!text-neutral-900
          focus:outline-0 focus:border-0
        "
      >
        <ChevronLeft className="mr-xxs h-lg w-lg neutral-800" />
        <div className="truncate max-w-full">{backLinkLabel}</div>
      </Link>

      <ol className="hidden tablet:flex p-0 mb-0 list-none">
        {crumbs.length <= MAX_STEPS ? (
          parentPages.map(parent => <ParentItem key={parent.url} crumb={parent} icon={icon} />)
        ) : (
          <>
            <ParentItem crumb={firstParent} icon={icon} />
            <li className="mr-xxs flex items-center text-neutral-600">
              <div className="mr-xxs">...</div>
              <div className="flex items-center">
                <ChevronRight className="h-md w-md" />
              </div>
            </li>
          </>
        )}
        <li className="!text-neutral-900">{currentPage.name}</li>
      </ol>
    </div>
  );
};
