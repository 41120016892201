import React from 'react';

import classNames from 'classnames';

type ReassuranceItem = {
  title: string;
  subtitle: string;
  Icon: React.ReactElement;
};

type ReassuranceBlockProps = {
  reassuranceItems: ReassuranceItem[];
  vertical?: boolean;
};

export const ReassuranceBlock: React.FC<ReassuranceBlockProps> = ({
  vertical,
  reassuranceItems,
}) => (
  <div
    className={classNames(
      'mt-md !bg-neutral-300 py-lg sm-desktop:mx-auto rounded-xxs px-md shadow-xxs tablet:px-lg',
      {
        ['mx-md']: !vertical,
      },
    )}
  >
    <div
      className={classNames('flex flex-col', {
        ['sm-desktop:flex-row sm-desktop:justify-between']: !vertical,
      })}
    >
      {reassuranceItems.map(({ title, subtitle, Icon }) => (
        <div
          key={title}
          className={classNames('flex items-center first:mt-0 mt-sm', {
            ['sm-desktop:mt-0']: !vertical,
          })}
        >
          <div className="w-[46px] h-[46px] bg-neutral-800 rounded-full">
            <div className="flex justify-center items-center h-full">
              <div className="w-xl h-xl text-neutral-200">{Icon}</div>
            </div>
          </div>
          <div className="flex flex-col justify-center ml-md">
            <div className="text-b2 font-bold">{title}</div>
            <div className="text-xs">{subtitle}</div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
