import { useState } from 'react';

import CrossCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CrossCircle.svg?react';
import Envelope from '@travauxlib/shared/src/components/DesignSystem/assets/Envelope.svg?react';
import Phone from '@travauxlib/shared/src/components/DesignSystem/assets/Phone.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { OverlayContainer } from '@travauxlib/shared/src/components/DesignSystem/components/OverlayContainer';
import { MobileToolbar } from '@travauxlib/shared/src/features/LDDToolbar/components/Mobile';
import { TypeRemise } from '@travauxlib/shared/src/types';

import { Details } from 'features/Estimateur/features/Result/components/Details';
import { Prestations } from 'features/Estimateur/features/Result/components/Prestations';
import { useShouldShowRecallButton } from 'features/Estimateur/hooks/useShouldShowRecallButton';
import { Answer, Estimation } from 'features/Estimateur/types';
import { heightEstimateur } from 'features/Estimateur/utils/heightEstimateur';
import { useIsEstimateurPublic } from 'features/Estimateur/utils/paths';

interface Props {
  estimation: Estimation;
  submitAnswer: (answer: Answer, index?: number) => Promise<unknown>;
  submitEstimation: (estimation: Estimation) => Promise<unknown>;
  deleteAnswer: (answerIndex: number) => Promise<unknown>;
  isClientEstimateur: boolean;
  toggleRecallModal: () => void;
  openLeadModal: () => void;
}

export function Mobile(props: Props): React.ReactElement {
  const [isSummaryPanelOpen, setIsSummaryPanelOpen] = useState<boolean>(false);
  const [isPrestationsOpen, setIsPrestationOpen] = useState<boolean>(false);
  const isPublic = useIsEstimateurPublic();

  const {
    estimation,
    isClientEstimateur,
    submitAnswer,
    submitEstimation,
    deleteAnswer,
    toggleRecallModal,
    openLeadModal,
  } = props;

  const showRecallButton = useShouldShowRecallButton(estimation.googleMapsAddress);

  return (
    <div>
      <div className="flex">
        {isPrestationsOpen && (
          <div className="relative border-r">
            <OverlayContainer active={isPrestationsOpen} height={heightEstimateur}>
              <div className="p-sm mb-lg">
                <div className="relative p-sm mb-lg">
                  <div className="absolute top-xs right-xs">
                    <button
                      type="button"
                      onClick={() => setIsPrestationOpen(false)}
                      data-testid="close-banner"
                      className="!flex !items-center !justify-center bg-transparent !p-xxs"
                    >
                      <CrossCircle className="w-lg h-lg text-neutral-600" />
                    </button>
                  </div>
                </div>
                <Prestations
                  estimation={estimation}
                  submitAnswer={submitAnswer}
                  deleteAnswer={deleteAnswer}
                />
              </div>
            </OverlayContainer>
          </div>
        )}
        <div
          style={{ overflowY: 'auto', maxHeight: heightEstimateur }}
          className="grow px-sm pt-sm"
        >
          <Details
            isClientEstimateur={isClientEstimateur}
            estimation={estimation}
            submitEstimation={submitEstimation}
          />
        </div>
      </div>
      <div className="z-20 fixed inset-x-0 bottom-0 overflow-x-auto">
        <div className="flex items-center bg-white border-t">
          {!isSummaryPanelOpen && (
            <Button
              leftIcon={<PlusSymbol />}
              data-testid="open"
              onClick={() => setIsPrestationOpen(isPrestationsOpen => !isPrestationsOpen)}
              className="flex items-center justify-center mx-auto"
            >
              Ajouter
            </Button>
          )}
          <div className="grow">
            <MobileToolbar
              activatedMarginCalculation={false}
              prestations={{
                defaultMargin: 0,
                lots: [],
                lotsByLocations: [],
                locations: [],
                createdAt: '',
                customerName: '',
                defaultTauxTVA: 10,
                numero: '',
                prixTotalHT: estimation.result?.totalHT ?? 0,
                prixTotalTTCAvantPrimes: estimation.result?.totalTTC ?? 0,
                prixTotalTTC: estimation.result?.totalTTC ?? 0,
                prixTotalHTAvantRemise: 0,
                prixTotalTTCAvantRemise: 0,
                montantsTVA: [
                  {
                    taux: 0,
                    base: 0,
                    montant: estimation.result?.totalTVA ?? 0,
                  },
                ],
                prixTotalFournitureHT: 0,
                remises: { type: TypeRemise.Empty, values: [] },
                montantRemise: 0,
                primes: [],
                lastModifiedAt: '',
                showFournitures: false,
                showMainOeuvre: false,
              }}
              hideDetailsTVA
              isEstimateur
              displayMenu={v => setIsSummaryPanelOpen(!v)}
              isMenuDisplayed={!isSummaryPanelOpen}
              bottomCta={
                isSummaryPanelOpen ? (
                  <>
                    {showRecallButton && (
                      <div className="bg-lighter mx-md">
                        <Button leftIcon={<Phone />} onClick={() => toggleRecallModal()} fullwidth>
                          Je souhaite être rappelé
                        </Button>
                      </div>
                    )}
                    {!isClientEstimateur && !isPublic && (
                      <div className="bg-lighter mx-md mt-md">
                        <Button onClick={openLeadModal} leftIcon={<Envelope />} fullwidth>
                          Envoyer le projet à hemea
                        </Button>
                      </div>
                    )}
                  </>
                ) : null
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
