import React from 'react';

import classNames from 'classnames';
import ReactQuill from 'react-quill-new';

import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';
import { DevisCustomization } from '@travauxlib/shared/src/types';

import { WarningForReplacingSignature } from 'features/DevisPro/components/WarningForReplacingSignature';

import { Atout } from './components/Atout';

import './Introduction.css';

type Props = {
  title: string;
  isArchitecte: boolean;
  introductionLetter?: string;
  showMarketingHemea: boolean;
  showWarningJO?: boolean;
  customization?: DevisCustomization;
  replacedSignedDevisToken?: string;
  isPreChiffrage: boolean;
  additionalInfos?: string;
  additionalInfosOwnerFullName?: string;
};

const introductionLetterForPreChiffrage = `Madame, Monsieur,

Voici notre pré-chiffrage (offre non contractuelle) qui comporte l'intégralité des prestations de fournitures et main d'oeuvre.

Cet estimatif comporte un montant indicatif qui nécessitera la validation préalable de l'entreprise qui sera amenée à réaliser les travaux.

Nous restons à votre disposition pour toute question ou modification de ce pré-chiffrage.

Nous vous prions d'agréer, Madame, Monsieur, nos sincères salutations.`;

export const Introduction: React.FC<Props> = ({
  title,
  isArchitecte,
  introductionLetter,
  showMarketingHemea,
  showWarningJO,
  customization,
  replacedSignedDevisToken,
  isPreChiffrage,
  additionalInfos,
  additionalInfosOwnerFullName,
}) => {
  const introductionLetterContent = isPreChiffrage
    ? introductionLetterForPreChiffrage
    : introductionLetter;

  return (
    <>
      <h2 className={classNames('mb-lg', customization?.titleFontStyle)}>{title}</h2>
      {replacedSignedDevisToken && (
        <div className="bg-info-50 p-md mb-lg">
          <WarningForReplacingSignature replacedSignedDevisToken={replacedSignedDevisToken} />
        </div>
      )}
      <div className="mb-lg">
        <ReactQuill
          theme="bubble"
          value={introductionLetterContent?.replaceAll(/\n/g, '<br>')}
          modules={{ toolbar: [] }}
          readOnly
        />
      </div>
      {additionalInfosOwnerFullName && additionalInfos && (
        <div className="bg-neutral-100 p-md mb-lg rounded-xs flex">
          <Avatar text={getInitials(additionalInfosOwnerFullName)} />
          <div className="ml-xs">
            <div className="text-h5 font-bold">{additionalInfosOwnerFullName}</div>
            <div className="text-b2 text-neutral-600">Chef de Projet Travaux</div>
            <div className="text-neutral-700 italic">“{additionalInfos}”</div>
          </div>
        </div>
      )}
      {showWarningJO && (
        <div className="mb-lg">
          <b>Information importante : </b>
          <br /> En raison des Jeux Olympiques 2024 se déroulant principalement à Paris, nous tenons
          à vous informer que votre chantier pourrait subir des retards, qui ne seront pas
          imputables à l'entreprise. Dans ce cas précis, et s'il est confirmé que le retard n'est
          pas imputable à l'entreprise, le calcul des éventuelles pénalités de retard en tiendra
          compte.
        </div>
      )}
      {showMarketingHemea && (
        <div className="mb-lg">
          <Atout isArchitecte={isArchitecte} />
        </div>
      )}
    </>
  );
};
