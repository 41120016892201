import React from 'react';

import classNames from 'classnames';

export const PaidFacture: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <div
    className={classNames(
      'rounded-full border border-success p-md w-[360px] text-[40px] text-success',
      className,
    )}
    data-testid="paid-facture"
  >
    {children}
  </div>
);
