import React from 'react';

import { useNavigate } from 'react-router-dom';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ChantierPvWithDocuments, PvType } from '@travauxlib/shared/src/features/Chantiers/types';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';

import { Chantier } from 'features/EspaceClient3/types';

import { useValidatePv } from '../../api/useValidatePv';

type OwnProps = {
  pv: ChantierPvWithDocuments;
  chantier: Chantier;
  hasReserves: boolean;
  dateSignature: string;
};

type Props = OwnProps & {
  handleClose: () => void;
};

const frozenSentence = (
  { montantGeleIfReserves, montantGele }: Chantier,
  { pvType, hasReserves }: { pvType: PvType; hasReserves?: boolean },
): React.ReactElement => {
  const documentName = PvDocumentName[pvType];
  if (
    !hasReserves ||
    pvType === PvType.LeveeReserves ||
    !montantGeleIfReserves ||
    montantGeleIfReserves === montantGele
  ) {
    return (
      <div>
        Après avoir validé le procès-verbal de {documentName}, nous allons automatiquement libérer
        l'intégralité des <EURCurrency amount={montantGele} /> actuellement gelés sur votre compte
        séquestre.
      </div>
    );
  }

  const remaining = montantGele - montantGeleIfReserves;

  return (
    <div>
      Après avoir validé le procès-verbal de {documentName}, nous allons automatiquement libérer une
      partie des <EURCurrency amount={montantGele} /> actuellement gelés sur votre compte séquestre.{' '}
      <EURCurrency amount={montantGeleIfReserves} /> seront libéres immédiatement et{' '}
      <EURCurrency amount={remaining} /> seront conservés jusqu'à la levée des réserves.
    </div>
  );
};

const ValidatePvModal: React.FC<Props> = ({
  handleClose,
  pv,
  chantier,
  hasReserves,
  dateSignature,
}) => {
  const { validatePv, isLoading } = useValidatePv();
  const navigate = useNavigate();

  const showFrozenSentence = !!chantier.montantGele;

  return (
    <FeedbackMessages
      isOpen
      title="Valider le document"
      variant="warning"
      validateAction={{
        label: 'Valider le document',
        onClick: async () => {
          await validatePv({
            pvUuid: pv.uuid,
            pvType: pv.pvType,
            chantierUuid: pv.chantierUuid,
            hasReserves,
            dateSignature,
          });
          toast.success('Document(s) validé(s) avec succès', {
            actionLabel: showFrozenSentence ? 'Les fonds ont été libérés avec succès' : undefined,
          });

          handleClose();
          navigate(-1);
        },
        disabled: isLoading,
      }}
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
      }}
      message={
        <div className="font-normal text-b2 text-gray-600">
          {frozenSentence(chantier, { hasReserves, pvType: pv.pvType })}
        </div>
      }
    />
  );
};

export const useOpenValidatePvModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(ValidatePvModal, {
      ...props,
      size: 'md',
      closable: true,
    });
};
