import React from 'react';

import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { DocumentTag } from '@travauxlib/shared/src/types/api/domain/client/DocumentTag';

import { useProjectDocuments } from '../api/useProjectDocuments';
import { UploadProjectFiles } from '../components/UploadProjectFiles';

type OwnProps = {
  projectUuid: string;
  tag: DocumentTag;
  title: string;
  description: React.ReactNode;
};

type Props = OwnProps & {
  handleClose: () => void;
};

export const DocumentModal: React.FC<Props> = ({ projectUuid, tag, description, handleClose }) => {
  const { projectDocuments } = useProjectDocuments(projectUuid!);

  return (
    <UploadProjectFiles
      projectUuid={projectUuid}
      files={projectDocuments[tag] || []}
      tag={tag}
      handleCloseModal={handleClose}
      description={description}
    />
  );
};

export const useOpenDocumentModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(DocumentModal, {
      closable: true,
      ...props,
    });
};
